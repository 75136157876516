<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <img :src="appLogoImage" style="object-fit: cover; width: 120px; height: 120px" />
        <img :src="appName" style="object-fit: cover; width: 120px; height: 120px" />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img fluid :src="imgUrl" alt="Login V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            ยินดีต้อนรับสู่ Socio! 👋
          </b-card-title>
         

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form class="auth-login-form mt-2" @submit.prevent>
              <!-- email -->
              <b-form-group label="อีเมล" label-for="login-email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">รหัสผ่าน</label>
                  <b-link :to="{ name: 'auth-forgot-password-v2' }">
                    <small>ลืมรหัสผ่าน?</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

            <br> 

              <!-- submit buttons -->
              <b-button type="submit" variant="primary" block @click="validationForm">
                ลงชื่อเข้าใช้งาน
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <span>คุณยังไม่มีบัญชี ? </span>
            <b-link :to="{ name: 'register' }">
              <span>&nbsp;สร้างบัญชีใหม่ฟรี</span>
            </b-link>
          </b-card-text>

          <!-- divider -->
          <div class="divider my-2">
            <div class="divider-text">หรือ</div>
          </div>

          <!-- social buttons -->
          <div class="auth-footer-btn d-flex justify-content-center">
            <b-button type="submit" variant="primary" block size="lg" @click="login">
              ลงชื่อเข้าใช้งานด้วย LINE
            </b-button>
          </div>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import useAppConfig from "@core/app-config/useAppConfig";
import { computed } from "@vue/composition-api";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { getHomeRouteForLoggedInUser } from "@/auth/utils";
import useJwt from "@/auth/jwt/useJwt";
import api from "@/api";
import { $themeConfig } from "@themeConfig";
export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      password: "",
      userEmail: "",
      sideImg: require("@/assets/images/pages/login-v2.svg"),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      name_user: "",
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "light") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  created() {
    console.log(
      'localStorage.getItem("vuexy-skin") :>> ',
      localStorage.getItem("vuexy-skin")
    );
    // const { skin } = useAppConfig();
    // localStorage.setItem("vuexy-skin", 'light');
    if (
      localStorage.getItem("vuexy-skin") !== "light" ||
      !localStorage.getItem("vuexy-skin")
    ) {
      console.log("light :>> ");
      localStorage.setItem("vuexy-skin", "light");
      window.location.reload();
    }
    // skin.value === "light";
  },
  methods: {
    login() {
      var url =
        "https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=1657484988&redirect_uri=https://manager.socio.co.th/auth&state=dev12345&scope=profile%20openid%20email&nonce=09876xyz";
      window.location.href = url;
    },
    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          localStorage.setItem("vuexy-skin", "light");
          const params = {
            email: this.userEmail,
            password: this.password,
          };

          api
            .post("/login", params)
            .then((response) => {
              // console.log("response.data :>> ", response.data);
              // this.$swal({
              //   icon: "success",
              //   title: "สำเร็จ! กำลังเข้าสู่ระบบ",
              //   customClass: {
              //     confirmButton: "btn btn-success",
              //   },
              // });
              // this.$router.push("/login");
              useJwt.setToken(response.data.accessToken);
              useJwt.setRefreshToken(response.data.refreshToken);
              localStorage.setItem("userData", JSON.stringify(response.data));
              this.name_user = JSON.parse(localStorage.getItem("userData"));
              console.log("login this.name_user:>> ", this.name_user);
              const params = {
                id: this.name_user.user_main,
              };

              api
                .post("getstore", params, {
                  headers: {
                    Authorization: "Bearer " + localStorage.getItem("accessToken"),
                    "Content-Type": "application/json",
                  },
                })
                .then((response) => {
                  this.data = response.data.stores;
                  this.totalRows = response.data.total;
                  console.log(response.data);
                })
                .catch((error) => {
                  // window.location.reload();
                });
              this.$ability.update(response.data.ability);
              console.log("response.data.ability :>> ", response.data.ability);
              this.$router.replace(getHomeRouteForLoggedInUser("admin")).then(() => {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Welcome ${response.data.name}`,
                    icon: "CoffeeIcon",
                    variant: "success",
                    text: `You have successfully logged in as Admin. Now you can start to explore!`,
                  },
                });
              });
            })
            .catch((err) => {
              this.$swal({
                title: "เช็กข้อมูลของท่านอีกครั้ง!",
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },

  setup() {
    const { appName, appLogoImage } = $themeConfig.app;

    return {
      // App Name
      appName,
      appLogoImage,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
